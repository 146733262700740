import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { addTenantAPI } from "../api/backofficeApi"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { AddTenant } from "../models/api/backofficeApiTypes"

const addTenantSchema = Yup.object().shape({
  identifier: Yup.string().min(1, "Too Short!").max(20, "Too Long!").required("Required"),
  name: Yup.string().min(1, "Too Short!").max(20, "Too Long!").required("Required"),
  adminEmail: Yup.string().email("Invalid email").required("Required"),
  connectionString: Yup.string().min(10, "Too Short!").max(1000, "Too Long!").required("Required"),
  /* validUpto: Yup.date().required(), */
})

export default function AddTenantForm() {
  return (
    <div>
      <Formik
        initialValues={{
          identifier: "",
          name: "",
          adminEmail: "",
          connectionString: "",
          validUpto: new Date(),
        }}
        validationSchema={addTenantSchema}
        onSubmit={async (values: AddTenant) => {
          await new Promise((resolve) => setTimeout(resolve, 500))
          addTenantAPI(values)
            .then((data) => {
              alert("Tenant with ID: '" + data.toString() + "' added correctly")
            })
            .catch((data) => {
              alert(data)
            })
        }}
      >
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <label htmlFor="identifier">Identifier</label>
            <Field id="identifier" name="identifier" placeholder="Tenant identifier" type="text" />
            {errors.identifier && touched.identifier ? <div>{"Identifier: " + errors.identifier}</div> : null}
            <br></br>

            <label htmlFor="name">Name</label>
            <Field id="name" name="name" placeholder="Tenant name" type="text" />
            {errors.name && touched.name ? <div>{errors.name}</div> : null}
            <br></br>

            <label htmlFor="adminEmail">Email Address</label>
            <Field id="adminEmail" name="adminEmail" placeholder="mario@gmail.com" type="email" />
            {errors.adminEmail && touched.adminEmail ? <div>{errors.adminEmail}</div> : null}
            <br></br>

            <label htmlFor="connectionString">Connection string</label>
            <Field id="connectionString" name="connectionString" placeholder="Tenant connection string" type="text" />
            {errors.connectionString && touched.connectionString ? <div>{errors.connectionString}</div> : null}
            <br></br>

            <label htmlFor="validUpto">Expiring date</label>
            <DatePicker
              selected={values.validUpto}
              dateFormat="dd MMMM yyyy"
              name="validUpto"
              onChange={(validUpto) => setFieldValue("validUpto", validUpto)}
            />
            <br></br>

            <button type="submit">Submit</button>
          </Form>
        )}
      </Formik>
    </div>
  )
}
