import { Route, BrowserRouter as Router, Routes, Navigate } from "react-router-dom"
import { Provider } from "react-redux"
import store, { appInitAction } from "./store"
import TenantsList from "./pages/TenantsList"
import TenantDetails from "./pages/TenantDetails"
import PageLayout from "./layout/PageLayout"

function App() {
  return (
    <Provider store={store}>
      <PageLayout />

      <Router>
        <Routes>
          <Route path="/home" element={<TenantsList />} />
          <Route path="/tenantdetails/:tenantId" element={<TenantDetails />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
    </Provider>
  )
}

store.dispatch(appInitAction())

export default App
