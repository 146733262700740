import { configureStore, combineReducers, createAction } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import authReducer from "../auth/authSlice"
import mainSaga from "./appSaga"

/* Action emitted during app init */
export const appInitAction = createAction("APP_INIT")
export type AppInitAction = ReturnType<typeof appInitAction>

/* saga middleware */
const sagaMiddleWare = createSagaMiddleware()

/* reducers */
const reducers = combineReducers({
  auth: authReducer,
})

/* create store */
const store = configureStore({
  reducer: reducers,
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      /* disable thunk (use SAGA) */
      thunk: false,
      /* disable serializeable check to use redux-persist */
      serializableCheck: false,
    }).concat(sagaMiddleWare)
  },
})

/* run main saga event loop */
sagaMiddleWare.run(mainSaga)

export type RootState = ReturnType<typeof store.getState>

export default store
