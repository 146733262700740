import { useDispatch, useSelector } from "react-redux"
import { login, logout } from "../auth/authSlice"
import { RootState } from "../store"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Button from "react-bootstrap/Button"

export default function NavigationBar() {
  const dispatch = useDispatch()
  const auth = useSelector((state: RootState) => state.auth)

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#">Wamoo backoffice</Navbar.Brand>
        {!auth.account && (
          <Button variant="secondary" onClick={() => dispatch(login())}>
            Sign In
          </Button>
        )}
        {auth.account && (
          <Button variant="secondary" onClick={() => dispatch(logout())}>
            Sign Out
          </Button>
        )}
      </Container>
    </Navbar>
  )
}
