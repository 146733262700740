import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { getTenantDetailsAPI } from "../api/backofficeApi"
import { TenantInfo } from "../models/api/backofficeApiTypes"

export default function TenantDetailsView() {
  const [tenant, SetTenant] = useState<TenantInfo>()
  const { tenantId } = useParams<string>()

  useEffect(() => {
    tenantId &&
      getTenantDetailsAPI(tenantId)
        .then((result) => SetTenant(result))
        .catch(() => console.error("ERROR (TODO)"))
  }, [tenantId])

  return (
    <div>
      {tenant && (
        <div>
          <h6>{"Name: " + tenant.name}</h6>
          <h6>{"Admin email: " + tenant.adminEmail}</h6>
          <h6>{"ID: " + tenant.id}</h6>
          <h6>{"Identifier: " + tenant.identifier}</h6>
          <h6>{"Is active: " + String(tenant.isActive)}</h6>
          <h6>{"Issuer: " + tenant.issuer}</h6>
          <h6>{"Validate up to: " + tenant.validUpto}</h6>
          <h6>{"Connection string: " + tenant.connectionString}</h6>
        </div>
      )}
    </div>
  )
}
