import { AddTenant, UpdateTenant } from "../models/api/backofficeApiTypes"
import axiosBackoffice from "./clientApi"

export async function getTenantsAPI() {
  return axiosBackoffice.get("/backoffice/tenants").then((response) => response.data)
}

export async function getTenantDetailsAPI(tenantId: string) {
  return axiosBackoffice.get(`/backoffice/tenants/${tenantId}`).then((response) => response.data)
}

export async function addTenantAPI(payload: AddTenant) {
  return axiosBackoffice.post(`/backoffice/tenants/`, payload).then((response) => response.data)
}

export async function updateTenantAPI(tenantId: string, payload: UpdateTenant) {
  return axiosBackoffice.put(`/backoffice/tenants/${tenantId}`, payload).then((response) => response.data)
}
