import { RootState } from "../store"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"
import TenantDetailsView from "../components/TenantDetailsView"
import UpdateTenantButton from "../components/UpdateTenantButton"

export default function TenantDetails() {
  const auth = useSelector((state: RootState) => state.auth)

  if (auth.account) {
    return (
      <Container>
        <UpdateTenantButton /> <TenantDetailsView />
      </Container>
    )
  } else {
    return <Container>Sign In to see the Tenants</Container>
  }
}
