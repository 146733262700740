import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { useEffect, useState } from "react"
import { getTenantsAPI } from "../api/backofficeApi"
import { TenantInfo } from "../models/api/backofficeApiTypes"
import CardMessage from "./CardMessage"

export default function TenantsListView() {
  const [tenants, SetTenants] = useState<TenantInfo[]>([])

  useEffect(() => {
    getTenantsAPI()
      .then((data) => {
        SetTenants(data)
      })
      .catch(() => console.error("ERROR (TODO)"))
  }, [])

  return (
    <Row xs={1} md={2} lg={3}>
      {tenants.map((tenant, index) => (
        <div key={index}>
          <Col>
            <CardMessage
              identifier={tenant.identifier}
              name={tenant.name}
              id={tenant.id}
              isActive={tenant.isActive}
            ></CardMessage>
          </Col>
        </div>
      ))}
    </Row>
  )
}
