import { Container } from "react-bootstrap"
import { RootState } from "../store"
import { useSelector } from "react-redux"
import AddTenantButton from "../components/AddTenantButton"
import TenantsListView from "../components/TenantsListView"

export default function TenantsList() {
  const auth = useSelector((state: RootState) => state.auth)

  if (auth.account) {
    return (
      <Container>
        <AddTenantButton /> <TenantsListView />
      </Container>
    )
  } else {
    return <Container>Sign In to see the Tenants</Container>
  }
}
