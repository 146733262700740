import * as msal from "@azure/msal-browser"
import Bowser from "bowser"

export interface AuthConfiguration {
  clientId: string
  B2CDomain: string
  autorities: {
    login: string
    [key: string]: string
  }
  scopes: Array<string>
}

class AuthNotInitError extends Error {
  constructor() {
    super(
      "AuthService: this service has not been initialized! Be sure to call init method before start using this service"
    )
  }
}

class AuthService {
  private STORAGE_KEY = "Auth_Hint"
  private msalConfig?: msal.Configuration
  protected client?: msal.PublicClientApplication
  protected _account?: msal.AccountInfo
  protected configuration?: AuthConfiguration
  private browser = Bowser.getParser(window.navigator.userAgent)
  protected isIE = this.browser.satisfies({ ie: "<12" })

  constructor(configuration?: AuthConfiguration) {
    if (configuration) {
      this.init(configuration)
    }
  }

  public init(configuration: AuthConfiguration) {
    this.configuration = configuration

    const B2CDomain = `${configuration.B2CDomain}.b2clogin.com`
    const loginAuthority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.login}`
    this.msalConfig = {
      auth: {
        clientId: configuration.clientId,
        authority: loginAuthority,
        knownAuthorities: [B2CDomain],
      },
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
      system: {
        windowHashTimeout: 10000,
        iframeHashTimeout: 10000,
        loadFrameTimeout: 10000,
      },
    }

    this.client = new msal.PublicClientApplication(this.msalConfig)
  }

  private getRedirectURI() {
    return window.location.protocol + "//" + window.location.host + "/"
  }

  async login() {
    console.log("login")
    const loginAuthority = `https://${this.configuration?.B2CDomain}.b2clogin.com/${this.configuration?.B2CDomain}.onmicrosoft.com/${this.configuration?.autorities.login}`
    const options = {
      authority: loginAuthority,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      scopes: ["openid", "offline_access", ...this.configuration!.scopes],
      redirectUri: this.getRedirectURI(),
    }

    if (this.isIE) await this.loginRedirect(options)
    else await this.loginPopup(options)
  }

  private async loginRedirect(options: msal.RedirectRequest) {
    if (!this.client) throw new AuthNotInitError()
    await this.client.loginRedirect(options)
  }

  private async loginPopup(options: msal.PopupRequest) {
    if (!this.client) throw new AuthNotInitError()
    const loginResponse: msal.AuthenticationResult = await this.client.loginPopup(options)
    this.handleResponse(loginResponse)
  }

  async logout() {
    if (!this.client) throw new AuthNotInitError()
    console.log("logout")
    this.clearAccountHint()
    await this.client.logoutPopup()
  }

  public async loadModule() {
    if (!this.client) throw new AuthNotInitError()
    try {
      const response = await this.client.handleRedirectPromise()
      this.handleResponse(response)
      if (!this._account) {
        await this.attemptSilent()
      }
    } catch (err) {
      if (err instanceof msal.AuthError) console.log("Failed to loadModule")
    }
  }

  public async attemptSilent(): Promise<msal.AccountInfo | undefined> {
    if (!this.client) throw new AuthNotInitError()
    try {
      const loginHint: string | undefined = this.loadAccountHint()
      if (loginHint === undefined) {
        console.info("No session found")
        return
      }
      const redirectUri = this.getRedirectURI()
      const loginResponse = await this.client.ssoSilent({
        scopes: ["openid", "offline_access", ...(this.configuration?.scopes ?? [])],
        loginHint,
        redirectUri,
        /*         prompt: "none",
         */
      })
      this.handleResponse(loginResponse)
      return this._account
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        console.info("Silent SSO fail, fallback to interactive")
      } else {
        console.error(err)
        console.info("Unable to complete Silent SSO")
      }
    }
  }

  private handleResponse(response: msal.AuthenticationResult | null) {
    if (response != null) {
      this._account = response.account ?? undefined
    } else {
      this._account = this.selectAccount()
    }
    this.saveAccountHint(this._account)
  }

  private selectAccount(): msal.AccountInfo | undefined {
    if (!this.client) throw new AuthNotInitError()
    const accounts: msal.AccountInfo[] = this.client.getAllAccounts()
    if (accounts.length > 1) {
      console.info("Multiple Accounts found, selected first one")
      return accounts[0]
    } else if (accounts.length === 1) {
      return accounts[0]
    } else {
      console.info("No account found!")
      return undefined
    }
  }

  private saveAccountHint(account?: msal.AccountInfo) {
    if (account) localStorage.setItem(this.STORAGE_KEY, account.username)
  }

  private loadAccountHint(): string | undefined {
    const hint = localStorage.getItem(this.STORAGE_KEY)
    return hint || undefined
  }

  private clearAccountHint() {
    localStorage.removeItem(this.STORAGE_KEY)
  }

  async acquireToken(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
    if (!this.client) throw new AuthNotInitError()
    options = { ...options, redirectUri: this.getRedirectURI() }
    try {
      const response = await this.client.acquireTokenSilent(options)
      if (response == null) return await this.acquireTokenInteractive(options)
      return response
    } catch (err) {
      if (err instanceof msal.InteractionRequiredAuthError) {
        return await this.acquireTokenInteractive(options)
      } else {
        throw err
      }
    }
  }

  private async acquireTokenInteractive(options: msal.SilentRequest): Promise<msal.AuthenticationResult | undefined> {
    if (this.isIE) await this.acquireTokenRedirect(options)
    else return await this.acquireTokenPopup(options)
  }

  private async acquireTokenRedirect(options: msal.SilentRequest): Promise<void> {
    if (!this.client) throw new AuthNotInitError()
    await this.client.acquireTokenRedirect(options)
  }

  private async acquireTokenPopup(options: msal.SilentRequest): Promise<msal.AuthenticationResult> {
    if (!this.client) throw new AuthNotInitError()
    return await this.client.acquireTokenPopup(options)
  }

  public async getIdToken() {
    if (!this.client) throw new AuthNotInitError()
    const options: msal.SilentRequest = {
      scopes: ["openid"],
      account: this._account,
    }
    const token = await this.acquireToken(options)
    if (token)
      return {
        idToken: token.idToken,
        idTokenClaims: token.idTokenClaims,
      }
    else throw new Error("Unable to retrive token from B2C")
  }

  public getAccount(): msal.AccountInfo | undefined {
    return this._account
  }
}

class WamooAuthService extends AuthService {
  public async getApiToken(): Promise<string> {
    if (!this.client) throw new AuthNotInitError()
    const options: msal.SilentRequest = {
      scopes: ["openid", "offline_access", ...(this.configuration?.scopes ?? [])],
      account: this._account,
    }
    const token = await this.acquireToken(options)
    if (token) return token.accessToken
    else throw new Error("Unable to retrive token from B2C")
  }
}

const authService = new WamooAuthService({
  clientId: process.env.REACT_APP_MSAL_CLIENT_ID ?? "",
  B2CDomain: process.env.REACT_APP_MSAL_B2CDOMAIN ?? "",
  autorities: {
    login: process.env.REACT_APP_MSAL_AUTH_LOGIN ?? "",
  },
  scopes: [process.env.REACT_APP_MSAL_SCOPE ?? ""],
})

export default authService
