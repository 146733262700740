import { Formik, Form, Field } from "formik"
import * as Yup from "yup"
import { getTenantDetailsAPI, updateTenantAPI } from "../api/backofficeApi"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { TenantInfo, UpdateTenant } from "../models/api/backofficeApiTypes"

const updateTenantSchema = Yup.object().shape({
  name: Yup.string().min(1, "Too Short!").max(20, "Too Long!").required("Required"),
  adminEmail: Yup.string().email("Invalid email").required("Required"),
  connectionString: Yup.string().min(10, "Too Short!").max(1000, "Too Long!").required("Required"),
  issuer: Yup.string().min(1, "Too Short!").max(20, "Too Long!"),
  /* validUpto: Yup.date().required(), */
})

export default function UpdateTenantForm() {
  const { tenantId } = useParams<string>()
  const [tenant, SetTenant] = useState<TenantInfo>()
  const navigate = useNavigate()

  useEffect(() => {
    tenantId &&
      getTenantDetailsAPI(tenantId)
        .then((result) => SetTenant(result))
        .catch(() => console.error("ERROR (TODO)"))
  }, [tenantId])

  return (
    <div>
      {tenant && (
        <Formik
          initialValues={{
            name: "",
            adminEmail: "",
            connectionString: "",
            validUpto: new Date(),
            isActive: false,
            issuer: "",
          }}
          validationSchema={updateTenantSchema}
          onSubmit={async (values: UpdateTenant) => {
            await new Promise((resolve) => setTimeout(resolve, 500))
            updateTenantAPI(tenant.id, values)
              .then((data) => {
                alert("Tenant with ID: '" + data.toString() + "' updated correctly")
                navigate(`/home`)
              })
              .catch((data) => {
                alert(data)
              })
          }}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <label htmlFor="name">Name</label>
              <Field id="name" name="name" placeholder={tenant.name} type="text" />
              {errors.name && touched.name ? <div>{errors.name}</div> : null}
              <br></br>

              <label htmlFor="adminEmail">Email Address</label>
              <Field id="adminEmail" name="adminEmail" placeholder={tenant.adminEmail} type="email" />
              {errors.adminEmail && touched.adminEmail ? <div>{errors.adminEmail}</div> : null}
              <br></br>

              <label htmlFor="connectionString">Connection string</label>
              <Field id="connectionString" name="connectionString" placeholder={tenant.connectionString} type="text" />
              {errors.connectionString && touched.connectionString ? <div>{errors.connectionString}</div> : null}
              <br></br>

              <label htmlFor="issuer">Issuer</label>
              <Field id="issuer" name="issuer" placeholder={tenant.issuer} type="text" />
              {errors.issuer && touched.issuer ? <div>{errors.issuer}</div> : null}
              <br></br>

              <label htmlFor="isActive">Is active</label>
              <Field id="isActive" name="isActive" placeholder={tenant.isActive} type="checkbox" />
              <br></br>

              <label htmlFor="validUpto">Expiring date</label>
              <DatePicker
                selected={values.validUpto}
                dateFormat="dd MMMM yyyy"
                name="validUpto"
                onChange={(validUpto) => setFieldValue("validUpto", validUpto)}
              />
              <br></br>

              <button type="submit">Submit</button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}
