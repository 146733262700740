import { useState } from "react"
import Card from "react-bootstrap/Card"
import { useNavigate } from "react-router-dom"

interface TenantProps {
  identifier: string
  name: string
  id: string
  isActive: boolean
}

export default function CardMessage({ identifier, name, id, isActive }: TenantProps) {
  const [tenatCardColor, setTenatCardColor] = useState("light")
  const [tenatCardText, setTenatCardText] = useState("dark")
  const navigate = useNavigate()

  const setColor = () => {
    setTenatCardText("light")
    if (isActive) {
      setTenatCardColor("success")
    } else {
      setTenatCardColor("danger")
    }
  }

  const resetColor = () => {
    setTenatCardColor("light")
    setTenatCardText("dark")
  }

  return (
    <div>
      <Card
        bg={tenatCardColor}
        style={{ width: "18rem" }}
        text={tenatCardText === "dark" ? "dark" : "light"}
        className="mb-2"
        onMouseEnter={setColor}
        onMouseLeave={resetColor}
        onClick={() => navigate(`/tenantdetails/${id}`)}
      >
        <Card.Header>{identifier}</Card.Header>
        <Card.Body>
          <Card.Title>{name}</Card.Title>
          <Card.Text>{id}</Card.Text>
        </Card.Body>
      </Card>
    </div>
  )
}
