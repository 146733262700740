import axios from "axios"
import authService from "../auth/authService"

// Axios instance without authentication
const axiosBackoffice = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
})

axiosBackoffice.interceptors.request.use(
  async (config: any) => {
    const isBackoffice = config.url?.includes("backoffice")
    if (isBackoffice) {
      try {
        const token = await authService.getApiToken()
        config.headers.Authorization = `Bearer ${token}`
        /* config.headers["Authorization"] = `Bearer ${token}` */
      } catch (e) {
        console.error(e)
        Promise.reject(e)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default axiosBackoffice
