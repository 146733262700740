import NavigationBar from "../layout/NavigationBar"
import Footer from "./Footer"

export default function PageLayout() {
  return (
    <>
      <NavigationBar />
      <Footer />
    </>
  )
}
