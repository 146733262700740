import "./style/footer.css"

export default function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <p className="col-sm">&copy;{new Date().getFullYear()} Wamoo</p>
      </div>
    </div>
  )
}
